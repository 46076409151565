.bg-mask {
  overflow: hidden;
  position: relative;
}

.bg-mask::before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: .3;
}

@for $i from 1 through 9 {
  .bg-mask-op-#{$i}::before {
    opacity: 1 / 10 * $i;
  }
}

.bg-mask-content {
  position: relative;
}
