// Use on the elements wrap, group is more semantic name
.float-group::after {
  content: "";
  display: table;
  clear: both;
}

// Use on the fix element
.sc-clearfix {
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
}

.float-none {
  float: none;
}
