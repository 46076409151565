.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-center-center {
  background-position: center center;
}

.bg-left {
  background-position-x: left;
}

.bg-center {
  background-position-x: center;
}

.bg-right {
  background-position-x: right;
}

.bg-top {
  background-position-y: top;
}

.bg-middle {
  background-position-y: center;
}

.bg-bottom {
  background-position-y: bottom;
}
