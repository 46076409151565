// change to false if its not imported into bootstrap
$use-bootstrap: false;

.table-layout {
    display: table;
    table-layout: fixed;
    width: 100%;
}

$breakpoints : $sc-grid-breakpoints;
@if $use-bootstrap {
    $breakpoints : $grid-breakpoints;
}

@each $breakpoint-name, $breakpoint-value in $breakpoints {
    // if xs value = 0, set it global without media queries
    @if($breakpoint-value == 0) {
        .#{$breakpoint-name}-table-cell {
            display: table-cell;
            float: none;
        }
    }
    // breakpoint values that not equal to 0
    @else {
        @media screen and (min-width: $breakpoint-value) {
            .#{$breakpoint-name}-table-cell {
                display: table-cell;
                float: none;
            }
        }
    }
}
